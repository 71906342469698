import { RouteComponentProps, useLocation } from '@gatsbyjs/reach-router'
import { APAsyncButton, APCard, APColumn, APExpanded, APIcon, APImage, APPalette, APRow, APScrollView, APSizedBox, APText, getIt, handleErrorMessage } from 'ap-components';
import { PriceBreakdownCard, Product, QuoteModel, StrapiInsuranceTerm } from 'insurance-term';
import { useEffect, useState } from 'react';
import InsuranceTermServices from '../../Services/InsuranceTermServices';
import moment from 'moment';
import CMS2Services from '../../Services/CMS2Services';
import saveAs from 'file-saver'
import { Skeleton } from '@mui/material';
import { launchUrl } from '../DigilockerKYC/common';


export default function InsuranceTerm(props: RouteComponentProps) {
    const location = useLocation()
    const token = new URLSearchParams(location.search).get("token");
    const [quote, setQuote] = useState<QuoteModel>();
    const [strapi, setStrapi] = useState<StrapiInsuranceTerm>()

    async function getQuoteDetails() {
        if (!token) return;

        try {
            const res = await getIt(InsuranceTermServices).getPolicyPlanSummary(token)
            if (!res.osbQuoteData) {
                handleErrorMessage(Error("No quote data found"));
            }
            setQuote(res)
        } catch (error) {
            handleErrorMessage(error)
        }
    }

    // async function getPremium() {
    //     if (!token) return;

    //     try {
    //         const res = await getIt(InsuranceTermServices).getPremium(token)
    //         setPremium(res)
    //     } catch (error) {
    //         handleErrorMessage(error)
    //     }
    // }

    async function getProducts() {
        try {
            const response = await getIt(CMS2Services).getTermInsurance()
            setStrapi(response)
        } catch (error) {
            handleErrorMessage(error)
        }
    }

    useEffect(() => {
        getQuoteDetails()
        // getPremium()
        getProducts()
    }, [token])

    if (!token) {
        window.location.href = "/";
    }

    let selectedProduct: Product | undefined = undefined;

    if (strapi || quote) {
        selectedProduct = strapi?.products?.find(item => item.provider === quote?.provider)
    }

    if (!quote?.osbQuoteData) {
        return (
            <APColumn mainAxisSize='max' crossAxisAlignment='center'>
                <APText variant='h2'>No quote data found</APText>
            </APColumn>
        )
    }

    const { osbQuoteData: { sumAssured, age, coverTillAge, nameOfInsuree, occupation, smokerOrNot, annualIncome, basePremiumWithTax, totalPremiumWithTax, premiumPaymentFrequency, biUrl, brochureLink, addonsOrRiders, totalPremium } } = quote;

    return (
        <APColumn mainAxisSize='max' crossAxisAlignment='stretch'>
            <APExpanded>
                <APScrollView>
                    <APColumn>
                        <APColumn crossAxisAlignment='stretch' style={{ maxWidth: "600px", padding: "16px" }} gap='16px'>
                            <APRow mainAxisAlignment='center'>
                                <APImage width={181} height={24} src='/Assetplus_Logo.png' />
                            </APRow>
                            <APCard padding='12px'>
                                <APColumn crossAxisAlignment='stretch' gap='12px'>
                                    <APRow gap="8px">
                                        {
                                            selectedProduct?.providerIcon?.url ?
                                                <APImage style={{
                                                    maxWidth: "36px",
                                                    maxHeight: "24px"
                                                }} src={selectedProduct.providerIcon.url} />
                                                :
                                                <APSizedBox width="36px" height="24px" style={{ backgroundColor: APPalette["grey-500"] }} />
                                        }
                                        {
                                            selectedProduct?.planName ?
                                                <APExpanded>
                                                    <APText variant="h3">{selectedProduct?.planName}</APText>
                                                </APExpanded>
                                                : <Skeleton width={300} height={25} />
                                        }
                                    </APRow>
                                    {quote && <APRow>
                                        <APText>
                                            {`${nameOfInsuree} will have a life cover of `}
                                            <APText variant='h3' style={{ display: "inline" }}>{sumAssured?.currency(1, true)}</APText>
                                            {" "} till the age of {" "}
                                            <APText variant='h3' style={{ display: "inline" }}>{coverTillAge} years</APText>
                                        </APText>
                                    </APRow>}
                                    <InfoItem
                                        label="Name"
                                        value={nameOfInsuree ?? "-"}
                                    />
                                    <InfoItem
                                        label="Quote"
                                        value={quote?.quoteName ?? "-"}
                                    />
                                    <APRow crossAxisAlignment="start" gap="8px">
                                        <InfoItem
                                            label="Age"
                                            value={age.toString()}
                                        />
                                        <InfoItem
                                            label='Occupation'
                                            value={occupation ?? "-"}
                                        />
                                    </APRow>
                                    <APRow crossAxisAlignment="start" gap="8px">
                                        <InfoItem
                                            label='Smoke/Tobacco'
                                            value={smokerOrNot}
                                        />
                                        <InfoItem
                                            label='Annual Income'
                                            value={annualIncome.toString()}
                                        />
                                    </APRow>
                                    <APRow mainAxisSize="min" crossAxisAlignment="start" gap="16px">
                                        <APRow onClick={() => saveAs(brochureLink!)} mainAxisSize="min" crossAxisAlignment="start" gap="8px"  >
                                            <APIcon icon="download" size={20} color={APPalette['info-300']} />
                                            <APText variant="link-medium">Policy Brochure</APText>
                                        </APRow>
                                        <APRow onClick={() => saveAs(biUrl!)} mainAxisSize="min" crossAxisAlignment="start" gap="8px"  >
                                            <APIcon icon="download" size={20} color={APPalette['info-300']} />
                                            <APText variant="link-medium">Benefit Illustration</APText>
                                        </APRow>
                                    </APRow>
                                </APColumn>
                            </APCard>
                            {
                                addonsOrRiders.length > 0 &&
                                <APCard padding="12px">
                                    <APColumn crossAxisAlignment="stretch" gap="16px">
                                        <APColumn crossAxisAlignment="start">
                                            <APColumn crossAxisAlignment="start" gap="8px">
                                                <APText variant="paragraph-xsmall" color={APPalette["grey-500"]}>Add-ons and Riders</APText>
                                                {
                                                    addonsOrRiders.map(item => (
                                                        <PriceDetails
                                                            color={APPalette['grey-600']}
                                                            label={item.title}
                                                            value={item.premiumValue}
                                                        />
                                                    ))
                                                }
                                            </APColumn>
                                        </APColumn>
                                    </APColumn>
                                </APCard>
                            }
                            <APCard padding="12px">
                                <APColumn crossAxisAlignment="stretch" gap="16px">
                                    <APColumn crossAxisAlignment="start" gap="8px">
                                        <APText variant="paragraph-xsmall" color={APPalette["grey-500"]}>Price breakdown</APText>
                                        <APColumn crossAxisAlignment="start">
                                            <APColumn crossAxisAlignment="start" gap="8px">
                                                <PriceDetails color={APPalette['grey-600']} label="Total Premium" value={totalPremium} />
                                                <PriceDetails color={APPalette['grey-600']} label="Total Premium" value={totalPremiumWithTax} showSubtext={true} />
                                            </APColumn>
                                        </APColumn>
                                    </APColumn>
                                </APColumn>
                            </APCard>
                        </APColumn>
                    </APColumn>
                </APScrollView>
            </APExpanded>
        </APColumn>
    )
}
function InfoItem({ label, value }: { label: string, value: string }) {
    return <APColumn crossAxisAlignment="start" gap="4px">
        <APText variant="paragraph-xsmall" color={APPalette["grey-500"]}>{label}</APText>
        <APText variant="h4">{value}</APText>
    </APColumn>;
}


function getFrequencyText(quote: QuoteModel) {
    if (quote.policyPremiumTerm == 0 || !quote.paymentFrequency) {
        return "";
    }
    if (quote.policyPremiumTerm == 1) {
        return "for one time";
    }
    switch (quote.paymentFrequency) {
        case "monthly":
            return "every month for a term of";
        case "halfYearly":
            return "every six months for a term of";
        case "quarterly":
            return "every three months for a term of";
        case "annually":
            return "every term for";
        default:
            return "";
    }
}


function PriceDetails({ label, value, color, showSubtext }: { label: string, value?: number, color: string, showSubtext?: boolean }) {
    return (
        <APColumn crossAxisAlignment='start'>
            <APRow mainAxisAlignment="spaceBetween" crossAxisAlignment="start" gap="4px">
                <APText variant="paragraph-small">{label}</APText>
                {!!value && <APText variant="h4" color={color}>{value.currency(0)}</APText>}
            </APRow>
            {showSubtext && <APText color={APPalette['grey-500']} variant='paragraph-xsmall' >(inclusive of taxes)</APText>}
        </APColumn>
    )
}