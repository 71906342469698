import { Type } from "class-transformer";
import moment from "moment";
import OSBPlanSummary from "./OSBPlanSummary";

export class QuoteModel {
    createdAt!: string;
    updatedAt!: string;
    quoteNo!: number;
    quotationType!: string;
    _id!: string /// Quote Id

    adminId!: string;
    userName!: string;
    insurerName?: string;
    userId?: string;
    quoteName?: string;
    dob!: string;
    occupation!: string;
    annualIncome!: number;
    isSmoker!: boolean;
    maritalStatus!: string;
    gender!: string;
    education?: string;

    sumAssured?: number;
    coverUpto?: number;
    policyPremiumTerm?: number;
    paymentFrequency?: string;
    status!: string;
    productId?: string
    provider?: string;

    premium?: Premium;
    bseClientCode?: number;


    @Type(() => RiderDetails)
    riders!: RiderDetails[];

    @Type(() => OSBPlanSummary)
    osbQuoteData?: OSBPlanSummary;

    constructor(data: any) {
        Object.assign(this, data);
    }

    static getSavedQuotes(quotes: QuoteModel[]) {
        return quotes.filter(quote => ["product", "completed"].includes(quote.status));
    }

    isCompleted() {
        return this.status === "completed";
    }

    getPaymentMode(): string | undefined {
        if (!this.policyPremiumTerm || !this.dob || !this.coverUpto) {
            return undefined
        }

        const age = moment().diff(moment(this.dob), 'years')
        const policyTerm = this.coverUpto - age

        if (this.policyPremiumTerm === 1) {
            return "single";
        } else if (this.policyPremiumTerm === policyTerm) {
            return "regular";
        }
        return "limited";
    }
}

export class RiderDetails {
    riderPlanCode!: string
    sumAssured?: number
    waiverOfPremium?: boolean
}

export class Premium {
    serviceTax!: number
    discount?: number;
    totalPremium!: number
    totalPremiumWithTax!: number
    basePremium!: number
    basePremiumWithTax!: number
    ridersPremium!: RiderPremium[]
}

export class RiderPremium {
    riderPlanCode!: string
    premium!: number
}