import { Type } from "class-transformer";

export default class OSBPlanSummary {
    sumAssured!: number;
    age!: number;
    coverTillAge!: number;
    nameOfInsuree!: string;
    occupation!: string;
    smokerOrNot!: string;
    annualIncome!: number;
    basePremium!: number;
    basePremiumWithTax!: number;
    totalPremiumWithTax!: number;
    totalPremium!: number;
    premiumPaymentFrequency!: string;
    biUrl!: string;
    brochureLink!: string;

    @Type(() => AddonOrRider)
    addonsOrRiders!: AddonOrRider[];

    constructor(data: any) {
        Object.assign(this, data);
    }
}

class AddonOrRider {
    title!: string;
    description!: string;
    premiumValue?: number;
    premiumValueWithTax?: number;

    constructor(data: any) {
        Object.assign(this, data);
    }
}