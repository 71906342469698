import NotFoundPage from "../NotFoundPage";
import { Router } from '@gatsbyjs/reach-router';
import RegulatoryValidationRoutes from "./RegulatoryValidation/RegulatoryValidationRoutes";
import WebStoryRoutes from "./WebStory";
import HealthInsurance from "./HealthInsurance";
import Calculator from "./CalculatorTab/Calculator";
import ProfileFrameDownloader from "./ProfileFrameDownloader";
import { KYCContactValidation } from "./KYCContactValidation/KycContactValidationForm";
import InsuranceTerm from "./InsuranceTerm";
import LAMFVoltMoney from "./LAMFVoltMoney";
import DigilockerKYCPage from "./DigilockerKYC/DigilockerKYC";
import ClientActivationPage from "./ClientActivation/ClientActivation";
import EventDialog from "./Events";
import NomineeRegulatoryValidationRoutes from "./NomineeRegulatoryValidation/NomineeRegulatoryValidationRoutes";
import ApproveAccountDeletion from "./ApproveAccountDeletion";
import OSBTermInsurance from "./OSBTermInsurance";
import NipponKYCPage from "./NipponKYC/NipponKyc";
import MasterKYCPage from "./MasterKYC/MasterKyc";

export default function App() {
    return (
        <Router style={{ display: "flex", height: "100%" }}>
            <NotFoundPage path="/" default />
            <RegulatoryValidationRoutes path="/regulatory_validation/*" />
            <NomineeRegulatoryValidationRoutes path="/nominee_regulatory_validation/*" />
            <DigilockerKYCPage path="/digilocker_kyc/*" />
            <NipponKYCPage path="/nippon_kyc/*" />
            <MasterKYCPage path="/master_kyc/*" />
            <WebStoryRoutes path="/web_story/*" />
            <HealthInsurance path="/ins_health/*" />
            <InsuranceTerm path="ins_term" />
            <OSBTermInsurance path="ins_term_osb/*" />
            <Calculator path="calculators/:calculator" />
            <ProfileFrameDownloader path="profile_frame_downloader" />
            <KYCContactValidation path="kyc_contact_validation" />
            <LAMFVoltMoney path="lamf" />
            <ClientActivationPage path="activation" />
            <ApproveAccountDeletion path="approve_deletion_request/:requestId" />
            <EventDialog path="events/*" />
        </Router>
    );
}