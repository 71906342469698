
import Axios, { AxiosInstance } from "axios";
import { plainToInstance } from "class-transformer";
import { singleton } from "tsyringe";
import { Premium, QuoteModel } from "insurance-term"

@singleton()
export default class InsuranceTermServices {
    instance: AxiosInstance = Axios.create({
        baseURL: import.meta.env.REACT_APP_TERM_INSURANCE_URL + "/mweb"
    });
    constructor() {
        this.instance.interceptors.request.use(config => {
            return config;
        }, (err) => Promise.reject(err));

        this.instance.interceptors.response.use(e => e, err => {
            if (err.response.data.userMsg)
                err.message = err.response.data.userMsg;
            return Promise.reject(err);
        })
    }

    async getQuoteDetails(token: string) {
        const response = await this.instance({
            method: "GET",
            url: "/getQuote",
            headers: {
                "authorization": "Bearer " + token
            }
        })

        return plainToInstance(QuoteModel, response.data)
    }

    async getPremium(token: string) {
        var response = await this.instance({
            method: "GET",
            url: "/premium",
            headers: {
                "authorization": "Bearer " + token
            }
        })

        return plainToInstance(Premium, response.data);
    }

    async getPremiumPdf(token: string): Promise<string> {
        var response = await this.instance({
            method: "GET",
            url: "/premiumPdf",
            headers: {
                "authorization": "Bearer " + token
            }
        })

        return response.data;
    }

    async leadSignUp(token: string): Promise<string> {
        var response = await this.instance({
            method: "POST",
            url: "/leadSignUp",
            headers: {
                "authorization": "Bearer " + token
            }
        })

        return response.data;
    }

    async getPolicyPlanSummary(token: string) {
        const response = await this.instance({
            method: "GET",
            url: "/planSummary",
            headers: {
                "authorization": "Bearer " + token
            }
        })

        return plainToInstance(QuoteModel, response.data)
    }
}